<template>
  <div class="w-content">

    <h2 class="title text-center">Smart Contracts</h2>
    <div class="row">
      <router-link :to="`/${$i18n.locale}/wallet/create-token`" class="link fo_link">Back</router-link>
    </div>

    <form @submit.prevent="search" method="post">
      <div class="form-group">
        <input v-model="searchContract" type="text" required placeholder="Search contract" class="main-input" />
      </div>
      <div class="form-group">
        <button class="bold-link bttn" @click="search(e)">Search</button>
      </div>
    </form>

    <div class="row">
      <select v-model="selectedMethod" v-if="success" class="select main-input" @change="onChange($event)">
        <option v-for="item in result" :value="item.name">{{ item.name }}</option>
      </select>
    </div>

    <form v-if="hidden" @submit.prevent="executeSmartHandler" method="post">
      <div class="form-group">
        <label class="main-input">
          <input type="checkbox" v-model="forgetNewState" class="form-check-label" />
          Save the result on the blockchain
        </label>
      </div>
      <div class="form-group">
        <input type="text" v-model="fee" required placeholder="Fee" class="main-input" />
      </div>
      <template v-for="item in params">
        <div class="form-group">
          <input type="text" :id=item :ref=item :placeholder=item required class="main-input" />
        </div>
      </template>
      <div class="form-group">
        <button class="bold-link bttn" v-if="success" @click="executeSmartHandler(e)">Execute</button>
      </div>
      <div class="form-group">
        <p class="smart__p fontBold font_l_h_2 mb-10">{{ smartContractResult }}</p>
      </div>
    </form>

  </div>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";

  export default {
    data: () => ({
      searchContract: "",
      smartContractResult: "",
      excludeMethods: ["payable"],
      result: [],
      params: [],
      success: false,
      hidden: false,
      fee: "0.1",
      forgetNewState: false
    }),

    methods: {
      search(e) {
        e.preventDefault();
        this.$loading(false);
        this.displayedPreloader = true;
        let postData = {
          methodApi: "GetContract",
          publicKey: this.searchContract,
          networkAlias: this.networkAlias
        };

        let postContent = JSON.stringify(postData);
        new Promise((resolver, reject) => {
          this.$loading(true);
          axios.post("../../api/wallet/GetData", postContent, {
            headers: {
              "Content-Type": "application/json"
            }
          })
            .then(x => {
              this.result = x.data.listItem.filter(x => !this.excludeMethods.includes(this.parseMethodName(x.name)));
              this.success = true;
              this.$loading(false);
            })
            .catch(x => {
              console.log("Error load data");
              console.log(x);
              this.$loading(false);
            });
        });
      },

      parseMethodName: function (data) {
        return data.split(' ')[1].split('(')[0];
      },

      onChange(event) {
        this.hidden = true;

        let s = event.target.value.indexOf('(');
        let e = event.target.value.indexOf(')');
        let p = event.target.value.substring(s + 1, e);
        let a = p.split(',');
        this.params = []
        a.forEach(x => {
          if (x.trim() !== '') {
            this.params.push(x.trim())
          }
        });
      },

      executeSmartHandler: function (e) {

        e.preventDefault();
        this.$loading(false);
        this.displayedPreloader = true;

        let postData = {
          publicKey: this.publicKey,
          contractPublicKey: this.searchContract,
          methodApi: "SmartMethodExecute",
          contractMethod: this.parseMethodName(this.selectedMethod),
          fee: parseFloat(this.fee),
          forgetNewState: !this.forgetNewState,
          networkAlias: this.networkAlias,
          contractParams: []
        };

        this.params.forEach(x => {
          let param = x.split(' ');
          let val = this.$refs[x][0].value;

          if (param[0] === 'String')
            postData.contractParams.push({ "name": param[1], "valString": val });
          else if (param[0] === 'int')
            postData.contractParams.push({ "name": param[1], "valInt": parseInt(val) });
          else if (param[0] === 'double')
            postData.contractParams.push({ "name": param[1], "valDouble": parseFloat(val) });
          else if (param[0] === 'boolean')
            postData.contractParams.push({ "name": param[1], "valBool": val.toLowerCase() === "true" ? true : false });
          else
            postData.contractParams.push({ "name": param[1], "valString": val });
        });

        let headers = {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Content-Encoding': 'utf-8'
        };

        var _this = this;
        new Promise((resolve, reject) => {
          _this.disabledForm = true;
          _this.$loading(true);
          axios.post("../../api/wallet/PackTransaction", postData, { headers })
            .then(r => {
              this.responsePackTransaction = r.data;
              postData.transactionPackagedStr = r.data.dataResponse.transactionPackagedStr;
              _this.$loading(false);
              this.executeSmartExecuteTr(postData);
            })
            .catch(ex => {
              console.log(ex);
            })
        });
      },
      executeSmartExecuteTr(data) {
        let headers = {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Content-Encoding': 'utf-8'
        };

        let _signature;
        try {
          _signature = nacl.sign.detached(
            Base58.decode(data.transactionPackagedStr),
            Base58.decode(this.privateKey));
          data.transactionSignature = Base58.encode(_signature);
        } catch (error) {
          console.log(error);
          alert("Execute smart signature completed with errors!");
        }

        this.$loading(true);
        setTimeout(() => {
          if (this.$loading === true) {
            this.$loading(false);
            alert("The wait time has expired");
          }
        }, 10000);
        axios.post("../../api/wallet/executeTransaction", data, { headers })
          .then(r => {
            this.disabledForm = false;
            if (r.data.success === true) {
              this.smartContractResult = r.data.dataResponse.smartContractResult;
              this.$loading(false);
            } else {
              console.log(r.data.message);
              this.$loading(false);
            }
          });
      }
    },
    computed: {
      ...mapState({
        publicKey: s => s.publicKey,
        privateKey: s => s.privateKey,
        networkAlias: s => s.networkAlias,
        createTokenData: s => s.createSmartData
      })
    }
  }
</script>
